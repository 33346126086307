import React, { Component } from  'react'

export default class CTA extends Component {
    render(){
        return(
<section id="contact">
        <div className="row section-intro">
          <div className="col-twelve">
            <h5>Contact</h5>
            <h1>I'd Love To Hear From You.</h1>
            <p className="lead">Every second is valuable. So don't hesitate... Say Hi.. and we will build up from there..</p>
          </div> 
        </div> {/* /section-intro */}
        <div className="row contact-info">
          <div className="col-four tab-full">
            <div className="icon">
              <i className="icon-pin" />
            </div>
            <h5>Where am I located at</h5>
            <p>
              Kandy/Colombo , Sri Lanka
            </p>
          </div>
          <div className="col-four tab-full collapse">
            <div className="icon">
              <i className="icon-mail" />
            </div>
            <h5>Email Me At</h5>
            <p>me@achalaweerasooriya.com		     
            </p>
          </div>
          <div className="col-four tab-full">
            <div className="icon">
              <i className="icon-phone" />
            </div>
            <h5>Call Me At</h5>
            <p>Voice/SMS: (+94) 714 807623<br />
              Whatsapp: (+94) 757 473276<br />
            </p>
          </div>
          <div className="col-four tab-full">
            <div className="icon">
              <i className="icon-web" />
            </div>
            <h5></h5>
            <p>
            </p>
          </div>
          <div className="col-four tab-full">
            <div className="icon">
              <i className="icon-web" />
            </div>
            <h5>Companies</h5>
            <p>Innovera: <a href="https://www.innovera.xyz" target="_blank">https://www.innovera.xyz</a> <br />
              Vaag Media: <a href="https://www.vaagmedia.com" target="_blank">https://www.vaagmedia.com</a> <br />
              LiveRoom: <a href="https://www.liveroom.xyz" target="_blank">https://www.liveroom.xyz</a> <br />
            </p>
          </div>
          <div className="col-four tab-full">
            <div className="icon">
              <i className="icon-web" />
            </div>
            <h5></h5>
            <p>
            </p>
          </div>
        </div> 
      </section>
        );
    }
};