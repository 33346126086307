import React, { Component } from  'react'

export default class Stats extends Component {
    render(){
        return(
<section id="stats" className="count-up">
        <div className="row">
          <div className="col-twelve">
            <div className="block-1-6 block-s-1-3 block-tab-1-2 block-mob-full stats-list">
              <div className="bgrid stat">
                <div className="icon-part">
                  <i className="icon-pencil-ruler" />
                </div>
                <h3 className="stat-count">
                  100+
                </h3>
                <h5 className="stat-title">
                  IT Projects Completed
                </h5>
              </div> {/* /stat */}					
              <div className="bgrid stat">
                <div className="icon-part">
                  <i className="icon-users" />
                </div>
                <h3 className="stat-count">
                  100+
                </h3>
                <h5 className="stat-title">
                  Public Speaking Sessions
                </h5>
              </div> {/* /stat */}
              <div className="bgrid stat">
                <div className="icon-part">
                  <i className="icon-badge" />
                </div>
                <h3 className="stat-count">
                  30+
                </h3>
                <h5 className="stat-title">
                  Events Hosted
                </h5>
              </div> {/* /stat */}									
              <div className="bgrid stat">
                <div className="icon-part">
                  <i className="icon-light-bulb" />
                </div>
                <h3 className="stat-count">
                  120+
                </h3>
                <h5 className="stat-title">
                  Crazy Ideas
                </h5>
              </div> {/* /stat */}
              <div className="bgrid stat">
                <div className="icon-part">
                  <i className="icon-cup" />
                </div>
                <h3 className="stat-count">
                  5+
                </h3>
                <h5 className="stat-title">
                  Brands Supported
                </h5>
              </div> {/* /stat */}
              <div className="bgrid stat">
                <div className="icon-part">
                  <i className="icon-clock" />
                </div>
                <h3 className="stat-count">
                  10+
                </h3>
                <h5 className="stat-title">
                  Mentoring Sessions
                </h5>
              </div> {/* /stat */}
            </div> {/* /stats-list */}
          </div> {/* /twelve */}
        </div> {/* /row */}
      </section>
        );
    }
};