import React, { Component } from  'react'

export default class Intro extends Component {
    render(){
        return(
            <section id="intro">   
            <div className="intro-overlay" />	
            <div className="intro-content">
            <div className="row">
                <div className="col-twelve">
                <h5>ආයුබෝවන්! | வணக்கம்! | Ayubowan!</h5>
                <h1>I'm <br></br>Achala<br></br>Weerasooriya</h1>
                <p className="intro-position">
                    <span>Techie</span>
                    <span>Media Sector Enthusiast</span>
                    <span>Growth Consultant</span>
                    <span>Entrepreneur</span>
                </p>
                <a className="button stroke smoothscroll" href="#about" >More AboutMe</a>
                </div>  
            </div>   		 		
            </div> {/* /intro-content */} 
            <ul className="intro-social">        
            <li><a href="https://www.facebook.com/achalawe"><i className="fa fa-facebook" /></a></li>
            <li><a href="https://www.linkedin.com/in/achalaweerasooriya/"><i className="fa fa-linkedin" /></a></li>
            <li><a href="https://www.youtube.com/channel/UCh9HN6oE8tFb2R6cTu2Nhbg"><i className="fa fa-youtube" /></a></li>
            <li><a href="https://www.instagram.com/ach_nbw/"><i className="fa fa-instagram" /></a></li>
            </ul> {/* /intro-social */}      	
           </section> /* /intro */
        );
    }
};