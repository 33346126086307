import React, { Component } from  'react'
import Popup from './popup';

export default class Portfolio extends Component {
        constructor(props){
          super(props);
          this.state = {
            link:'',
            showPopup:false,
            contenttype:''
          };
          this.togglePopup = this.togglePopup.bind(this);
      }

      togglePopup(url,type){
              this.setState({ link: url, showPopup:!this.state.showPopup, contenttype:type }, () => {
              });  
      }

    render(){
        return(
            <section id="portfolio">
                      {this.state.showPopup?
                          <Popup
                              text='Click "Close Me" to hide popup'
                              closePopup={()=>this.togglePopup("")}
                              link = {this.state.link}
                              type = {this.state.contenttype}
                          />
                      : null
                      }
                  
            <div className="row section-intro">
              <div className="col-twelve">
                <h5>Portfolio</h5>
                {/* written articles */ }
                <h1>Check Out Some of My Work</h1>
                <p className="lead">Here are a few articles I have written</p>
              </div>   		
            </div> {/* /section-intro*/} 
            <div className="row portfolio-content">
              <div className="col-twelve">
                {/* portfolio-wrapper */}
                <div id="folio-wrapper" className="block-1-2 block-mob-full stack">
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                      <img src="images/portfolio/articles/appreciativeinquiry_banner.jpeg" alt="AppreciativeInquiry" />
                      <button onClick={()=>this.togglePopup("<iframe src='https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6793135315163889665' height='694' width='504' frameborder='0' allowfullscreen='' title='Embedded post'></iframe>","article")} className="overlay">
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Appreciative Inquiry</h3>	     					    
                            <span className="folio-types">
                              Linkedin Article
                            </span>
                          </div>	                      	
                        </div>                    
                      </button>
                    </div>	               
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                      <img src="images/portfolio/articles/swotvssoar_banner.jpeg" alt="SWOTvsSOAR" />
                      <button onClick={()=>this.togglePopup("<iframe src='https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6792472618692870145' height='618' width='504' frameborder='0' allowfullscreen='' title='Embedded post'></iframe>","article")} className="overlay">              		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">SWOT or SOAR</h3>	     					    
                            <span className="folio-types">
                              Linkedin Article
                            </span>		     		
                          </div> 	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                      <img src="images/portfolio/articles/giversandtakers_banner.png" alt="GiveorTake" />
                      <button onClick={()=>this.togglePopup("<iframe src='https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6794529002968162304' height='565' width='504' frameborder='0' allowfullscreen='' title='Embedded post'></iframe>","article")} className="overlay">              		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Giver or Taker</h3>	     					    
                            <span className="folio-types">
                              Linkedin Article
                            </span>		     		
                          </div> 	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                      <img src="images/portfolio/articles/adapttech_banner.jpeg" alt="Adapting to tech" />
                      <button onClick={()=>this.togglePopup("<iframe src='https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6796787985548410880' height='480' width='640' frameborder='0' allowfullscreen='' title='Embedded post'></iframe>","article")} className="overlay">              		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Adapting to<br></br>New Tech</h3>	     					    
                            <span className="folio-types">
                              Linkedin Article
                            </span>		     		
                          </div> 	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                      <img src="images/portfolio/articles/arbasics_banner.jpeg" alt="Basics of AR" />
                      <button onClick={()=>this.togglePopup("<iframe src='https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6800795067427778560' height='684' width='504' frameborder='0' allowfullscreen='' title='Embedded post'></iframe>","article")} className="overlay">              		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Basics of<br></br>Augmented Reality</h3>	     					    
                            <span className="folio-types">
                              Linkedin Article
                            </span>		     		
                          </div> 	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                      <img src="images/portfolio/articles/gamification_banner.jpg" alt="Gamification VR" />
                      <button onClick={()=>this.togglePopup("<iframe src='https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6803989213286068224' height='711' width='504' frameborder='0' allowfullscreen='' title='Embedded post'></iframe>","article")} className="overlay">              		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Gamifying With<br></br>Virtual Reality</h3>	     					    
                            <span className="folio-types">
                              Linkedin Article
                            </span>		     		
                          </div> 	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                      <img src="images/portfolio/articles/ballapiduru_banner.jpeg" alt="ballapiduru" />
                      <button onClick={()=>this.togglePopup("<iframe src='https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fachalawe%2Fposts%2F10224553865182907&width=500&show_text=true&height=473&appId' width='500' height='300' style='border:none;overflow:hidden' scrolling='no' frameborder='5' allowfullscreen='true' allow='autoplay; allowtransparency='false'; clipboard-write; encrypted-media; display:block; background:white; picture-in-picture; web-share'></iframe>","article")} className="overlay">            		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">බල්ලා පිදුරු කන්නෙත් නෑ</h3>	     					    
                            <span className="folio-types">
                              Facebook Article
                            </span>		     		
                          </div> 	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                      <img src="images/portfolio/articles/cricket_rassawa.jpeg" alt="cricketrassawa" />
                      <button onClick={()=>this.togglePopup("<iframe src='https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fachalawe%2Fposts%2F10224216825477125&width=500&show_text=true&height=729&appId' width='500' height='729' style='border:none;overflow:hidden' scrolling='no' frameborder='0' allowfullscreen='true' allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'></iframe>","article")} className="overlay">                 	                 
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">ක්‍රිකට් රස්සාව</h3>	     					    
                            <span className="folio-types">
                              Facebook Article
                            </span>		     		
                          </div>  	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}     
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                      <img src="images/portfolio/articles/newnormal_banner.jpeg" alt="newnormal" />
                      <button onClick={()=>this.togglePopup("<iframe src='https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fachalawe%2Fposts%2F10223938652922985&width=500&show_text=true&height=256&appId' width='500' height='256' style='border:none;overflow:hidden' scrolling='no' frameborder='0' allowfullscreen='true' allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'></iframe>","article")} className="overlay">             		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">New Normal එපා වෙලාද</h3>	     					    
                            <span className="folio-types">
                              Facebook Article
                            </span>		     		
                          </div> 	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                      <img src="images/portfolio/articles/janadipathichande_banner.jpeg" alt="janadipathichande" />
                      <button onClick={()=>this.togglePopup("<iframe src='https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fachalawe%2Fposts%2F10222589820723023&width=500&show_text=true&height=538&appId' width='500' height='538' style='border:none;overflow:hidden' scrolling='no' frameborder='0' allowfullscreen='true' allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'></iframe>","article")} className="overlay">
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">ජනාධිපති ඡන්දේ</h3>	     					    
                            <span className="folio-types">
                              Facebook Artile
                            </span>		     		
                          </div>	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                      <img src="images/portfolio/articles/madeinsrilanka_banner.jpeg" alt="madeinsrilanka" />
                      <button onClick={()=>this.togglePopup("<iframe src='https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fachalawe%2Fposts%2F10221476905420836&width=500&show_text=true&height=544&appId' width='500' height='544' style='border:none;overflow:hidden' scrolling='no' frameborder='0' allowfullscreen='true' allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'></iframe>","article")} className="overlay">
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Made In Sri Lanka</h3>	     					    
                            <span className="folio-types">
                              Facebook Artile
                            </span>		     		
                          </div>	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                      <img src="images/portfolio/articles/iskolekale_banner.jpeg" alt="iskolekale" />
                      <button onClick={()=>this.togglePopup("<iframe src='https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fachalawe%2Fposts%2F10201890105083069&width=500&show_text=true&height=282&appId' width='500' height='282' style='border:none;overflow:hidden' scrolling='no' frameborder='0' allowfullscreen='true' allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'></iframe>","article")} className="overlay">
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">ඉස්කෝලේ කාලේ</h3>	     					    
                            <span className="folio-types">
                              Facebook Artile
                            </span>		     		
                          </div>	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                      <img src="images/portfolio/articles/mandate_banner.jpeg" alt="mandate" />
                      <button onClick={()=>this.togglePopup("<iframe src='https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fachalawe%2Fposts%2F10219973036785060&width=500&show_text=true&height=275&appId' width='500' height='275' style='border:none;overflow:hidden' scrolling='no' frameborder='0' allowfullscreen='true' allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'></iframe>","article")} className="overlay">
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">ප්‍රතිපත්ති ප්‍රකාශය<br></br>The Mandate</h3>	     					    
                            <span className="folio-types">
                              Facebook Artile
                            </span>		     		
                          </div>	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                </div> {/* /portfolio-wrapper */} 
              </div>  {/* /twelve */}   
            </div> {/* /portfolio-content */} 

            {/* webinars and videos */ }
            <br></br>
            <div className="row section-intro">
              <div className="col-twelve">
                <h1>Moving On...</h1>
                <p className="lead">Here are a few videos of sessions I've conducted</p>
              </div>   		
            </div> {/* /section-intro*/}  
            <div className="row portfolio-content">
              <div className="col-twelve">
                {/* portfolio-wrapper */}
                <div id="folio-wrapper" className="block-1-2 block-mob-full stack">
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                      <img src="images/portfolio/videosessions/vtasession_banner.jpeg" alt="VTAsession" />
                      <button onClick={()=>this.togglePopup("https://www.facebook.com/1496936117/videos/10219600439030349/","facebookvid")} className="overlay">                  	           
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Session For<br></br>VTA Students</h3>	     					    
                            <span className="folio-types">
                              On-site Visit
                            </span>
                          </div>	                      	
                        </div>                    
                      </button>
                    </div>	               
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                      <img src="images/portfolio/videosessions/d82ceotalks_banner.jpeg" alt="D82 CEO Talks" />
                      <button onClick={()=>this.togglePopup("https://www.facebook.com/235338403331565/videos/922283205000310","facebookvid")} className="overlay">             		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Toastmasters<br></br>D82 CEO Talks</h3>	     					    
                            <span className="folio-types">
                              Webinar Discussion
                            </span>		     		
                          </div> 	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/videosessions/agrithonsession_banner.jpeg" alt="Agrithon" />
                      <button onClick={()=>this.togglePopup("https://www.facebook.com/101089435278519/videos/1142191569551374","facebookvid")} className="overlay">                		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Agrithon</h3>	     					    
                            <span className="folio-types">
                              Webinar Session
                            </span>		     		
                          </div> 	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/videosessions/acmuom_banner.jpeg" alt="ACM UoM" />
                      <button onClick={()=>this.togglePopup("","")} className="overlay">                	                 
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Session for<br></br>ACM students chapter<br></br>At UoM</h3>	     					    
                            <span className="folio-types">
                              Webinar Session
                            </span>		     		
                          </div>  	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/videosessions/spiralationintro_banner.jpeg" alt="ACM UoM" />
                      <button onClick={()=>this.togglePopup("","")} className="overlay">                	                 
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Introducing<br></br>Spiralation<br></br>Under<br></br>Uva StartUp Hub</h3>	     					    
                            <span className="folio-types">
                              Webinar Session
                            </span>		     		
                          </div>  	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/videosessions/uopcse_banner.jpeg" alt="CSE UoM" />
                      <button onClick={()=>this.togglePopup("https://www.youtube.com/watch?v=xt-DysPL5RE","youtubevid")} className="overlay">                	                 
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Session for<br></br>UoP<br></br>Startup<br></br>Ecosystem</h3>	     					    
                            <span className="folio-types">
                              Webinar Session
                            </span>		     		
                          </div>  	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/videosessions/ieeeiesuom_banner.jpeg" alt="ACM UoM" />
                      <button onClick={()=>this.togglePopup("","")} className="overlay">                	                 
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Stepping<br></br>into the future<br></br>with<br></br>AR related<br></br>Technologies</h3>	     					    
                            <span className="folio-types">
                              Webinar Session
                            </span>		     		
                          </div>  	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                      <img src="images/portfolio/videosessions/slasscomARVRMR_banner.jpeg" alt="D82 CEO Talks" />
                      <button onClick={()=>this.togglePopup("https://fb.watch/8BefmFTmWJ/","facebookvid")} className="overlay">             		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">SLASSCOM<br></br>Tech Forum</h3>	     					    
                            <span className="folio-types">
                              Webinar Discussion
                            </span>		     		
                          </div> 	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                      <img src="images/portfolio/videosessions/EasternRoadShow_banner.jpeg" alt="Road Show" />
                      <button onClick={()=>this.togglePopup("https://www.facebook.com/startupsl.lk/videos/324257412868918","facebookvid")} className="overlay">                  	           
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Eastern Reigon Road Show<br></br>Growth Series</h3>	     					    
                            <span className="folio-types">
                              Webinar Session
                            </span>
                          </div>	                      	
                        </div>                    
                      </button>
                    </div>	               
                  </div> {/* /folio-item */}
                </div> {/* /portfolio-wrapper */} 
              </div>  {/* /twelve */}   
            </div> {/* /portfolio-content */} 


            {/* Event participation */ }
            <br></br>
            <div className="row section-intro">
              <div className="col-twelve">
                <h1>Continuing....</h1>
                <p className="lead">Here are some events that I had the opportunity to take part in</p>
              </div>   		
            </div> {/* /section-intro*/}  
            <div className="row portfolio-content">
              <div className="col-twelve">
                {/* portfolio-wrapper */}
                <div id="folio-wrapper" className="block-1-2 block-mob-full stack">
                <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/participatedsessions/seedstars_banner.jpeg" alt="Seedstars" />
                      <button onClick={()=>this.togglePopup("<iframe src='https://www.linkedin.com/embed/feed/update/urn:li:share:6586988174071955456' height='600' width='400' frameborder='0' allowfullscreen='' title='Embedded post'></iframe>","article")} className="overlay">   	                  	           
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Seedstars</h3>	     					    
                            <span className="folio-types">
                              Startup Pitching Competition
                            </span>		     		
                          </div>  	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/participatedsessions/exportawards_banner.jpeg" alt="Presedential Export Awards" />
                      <button onClick={()=>this.togglePopup("https://www.linkedin.com/posts/achalaweerasooriya_srilanka-opportunity-technology-activity-6580464069441085440-p2d4","noembed")} className="overlay">   	                  	           
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Presidential Export<br></br>Awards 2018/19</h3>	     					    
                            <span className="folio-types">
                              Awards Ceremony
                            </span>		     		
                          </div> 	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/participatedsessions/breakfastforum_banner.jpeg" alt="ICT Breakfast Forum" />
                      <button onClick={()=>this.togglePopup("https://www.linkedin.com/posts/achalaweerasooriya_networking-ict-tech-activity-6468389975770660864-FBL7","noembed")} className="overlay">   	                  	           
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Breakfast Forum<br></br>and Networking</h3>	     					    
                            <span className="folio-types">
                              Networking Event
                            </span>		     		
                          </div>	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/participatedsessions/opportunitiesinIT_banner.jpeg" alt="AHK Webinar" />
                      <button onClick={()=>this.togglePopup("https://www.linkedin.com/posts/achalaweerasooriya_attended-business-opportunities-in-it-and-activity-6727188333157511168-u-FV","noembed")} className="overlay">   	                  	           
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Business Opportunities<br></br>in IT<br></br>and Outsourcing</h3>	     					    
                            <span className="folio-types">
                              Webinar Session
                            </span>
                          </div>	                      	
                        </div>                    
                      </button>
                    </div>	               
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/participatedsessions/exportdevelopment_banner.jpeg" alt="Future Vision" />
                      <button onClick={()=>this.togglePopup("https://www.linkedin.com/posts/achalaweerasooriya_business-growth-innovation-activity-6705455388092874752-1OGt","noembed")} className="overlay">   	                  	           
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Future Vision:<br></br>Development of<br></br>Exports in<br></br>SL</h3>	     					    
                            <span className="folio-types">
                              Discussion Forum
                            </span>		     		
                          </div> 	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/participatedsessions/iesltechno_banner.jpeg" alt="IESL Techno" />
                      <button onClick={()=>this.togglePopup("https://www.linkedin.com/posts/achalaweerasooriya_techno2019-iesl-bmich-activity-6588333609990619136-ftuy","noembed")} className="overlay">   	                  	           
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">IESL Techno 2019</h3>	     					    
                            <span className="folio-types">
                              Exhibition - Sri Lanka
                            </span>		     		
                          </div> 	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}  
                </div> {/* /portfolio-wrapper */} 
              </div>  {/* /twelve */}   
            </div> {/* /portfolio-content */}


            {/* Foreign Visits */ }
            <br></br>
            <div className="row section-intro">
              <div className="col-twelve">
                <h1>Almost...</h1>
                <p className="lead">Here are some of the foreign business visits I was a part of.</p>
              </div>   		
            </div> {/* /section-intro*/}  
            <div className="row portfolio-content">
              <div className="col-twelve">
                {/* portfolio-wrapper */}
                <div id="folio-wrapper" className="block-1-2 block-mob-full stack">
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                      <img src="images/portfolio/foreignvisits/innovfestunbound_banner.jpeg" alt="Innovfest Unbound" />
                      <div className="overlay">              	           
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Innovfest Unbound<br></br>2016</h3>	     					    
                            <span className="folio-types">
                              Exhibition - Singapore
                            </span>
                          </div>	                      	
                        </div>
                        </div>                  
                    </div>	               
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/foreignvisits/japan2019_banner.jpeg" alt="JAPAN19" />
                      <div className="overlay">              		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">On-site Placement</h3>	     					    
                            <span className="folio-types">
                              Work Placement - Japan
                            </span>		     		
                          </div>	                      	
                        </div>                    
                      </div>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/foreignvisits/4yfn2018_banner.jpeg" alt="MWC18" />
                      <div className="overlay">              		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">MWC|4YFN 2018</h3>	     					    
                            <span className="folio-types">
                              Exhibition - Spain
                            </span>		     		
                          </div> 	                      	
                        </div>                    
                      </div>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/foreignvisits/gitex_banner.jpeg" alt="GITEX17" />
                      <div className="overlay">              		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">GITEX 2017</h3>	     					    
                            <span className="folio-types">
                              Exhibition - Dubai
                            </span>		     		
                          </div>  	                      	
                        </div>                    
                      </div>
                    </div>
                  </div> {/* /folio-item */}     
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/foreignvisits/cebit2018_banner.jpeg" alt="CEBIT18" />
                      <div className="overlay">              		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">CEBIT 2018</h3>	     					    
                            <span className="folio-types">
                              Exhibition - Germany
                            </span>		     		
                          </div> 	                      	
                        </div>                    
                      </div>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                      <img src="images/portfolio/foreignvisits/4yfn2017_banner.jpeg" alt="MWC17" />
                      <div className="overlay">              		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">MWC|4YFN 2017</h3>	     					    
                            <span className="folio-types">
                              Exhibition - Spain
                            </span>		     		
                          </div> 	                      	
                        </div>                    
                      </div>
                    </div>
                  </div> {/* /folio-item */}
                </div> {/* /portfolio-wrapper */} 
              </div>  {/* /twelve */}   
            </div> {/* /portfolio-content */}

            {/* Gallery */ }
            <br></br>
            <div className="row section-intro">
              <div className="col-twelve">
                <h1>And Also..</h1>
                <p className="lead">Here are a some of the captured moments of me with a mic or addressing an audience</p>
              </div>   		
            </div> {/* /section-intro*/}  
            <div className="row portfolio-content">
              <div className="col-twelve">
                {/* portfolio-wrapper */}
                <div id="folio-wrapper" className="block-1-2 block-mob-full stack">
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/gallery/samsungdealersnight_banner.jpeg" alt="Samsumg2020" />
                      <div className="overlay">              		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Samsung<br></br>Dealers Night<br></br>2020</h3>	     					    
                            <span className="folio-types">
                              Event Hosting
                            </span>
                          </div>	                      	
                        </div>                    
                      </div>
                    </div>	               
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/gallery/graduationnextgen_banner.jpeg" alt="ShiningStarsGrad" />
                      <div className="overlay">              		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Next Generation<br></br>Graduation<br></br>2015 and 2016</h3>	     					    
                            <span className="folio-types">
                              Event Hosting
                            </span>		     		
                          </div>	                      	
                        </div>                    
                      </div>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/gallery/sliitawrudu_banner.jpeg" alt="SLIITAWRUDU" />
                      <div className="overlay">              		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">SLIIT<br></br>AWRUDU</h3>	     					    
                            <span className="folio-types">
                              Event Hosting
                            </span>		     		
                          </div> 	                      	
                        </div>                    
                      </div>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/gallery/inaugrationsliit15_banner.jpeg" alt="SLIITInnaugration" />
                      <div className="overlay">              		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">SLIIT<br></br>Inauguration Ceremony<br></br>2015</h3>	     					    
                            <span className="folio-types">
                              Event Hosting
                            </span>		     		
                          </div>  	                      	
                        </div>                    
                      </div>
                    </div>
                  </div> {/* /folio-item */}     
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/gallery/branchopeninglr_banner.JPG" alt="BandarawelaBranchOpening" />
                      <div className="overlay">              		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">LiveRoom<br></br>Branch Opening<br></br>2020</h3>	     					    
                            <span className="folio-types">
                              Company Event
                            </span>		     		
                          </div> 	                      	
                        </div>                    
                      </div>
                    </div>
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/gallery/teamappreciation_banner.jpeg" alt="TeamAppreciation2019" />
                      <div className="overlay">              		                  
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">Team<br></br>Appreciation</h3>	     					    
                            <span className="folio-types">
                              Company Event
                            </span>		     		
                          </div> 	                      	
                        </div>                    
                      </div>
                    </div>
                  </div> {/* /folio-item */}
                </div> {/* /portfolio-wrapper */} 
              </div>  {/* /twelve */}   
            </div> {/* /portfolio-content */} 


            {/* YouTube Channel */ }
            <br></br>
            <div className="row section-intro">
              <div className="col-twelve">
                <h1>Now On To YouTube</h1>
                <p className="lead">Here are some of the playlists I have done in YouTube</p>
              </div>   		
            </div> {/* /section-intro*/}  
            <div className="row portfolio-content">
              <div className="col-twelve">
                {/* portfolio-wrapper */}
                <div id="folio-wrapper" className="block-1-2 block-mob-full stack">
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <iframe title='notetoself' width={560} height={315} src="https://www.youtube.com/embed/videoseries?list=PL4R4Rki7FWDev0aPdeLilznCDtu9iGSpz" frameBorder={0} allow="autoplay; encrypted-media" allowFullScreen />
                    </div>	               
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <iframe title='dannade' width={560} height={315} src="https://www.youtube.com/embed/videoseries?list=PL4R4Rki7FWDfVNp7VOTu-TE3s9wuaQQfV" frameBorder={0} allow="autoplay; encrypted-media" allowFullScreen />
                    </div>	               
                  </div> {/* /folio-item */}
                </div> {/* /portfolio-wrapper */} 
              </div>  {/* /twelve */}   
            </div> {/* /portfolio-content */}

            {/* Voice-over Content */ }
            <br></br>
            <div className="row section-intro">
              <div className="col-twelve">
                <h1>Finally..</h1>
                <p className="lead">Here are some of the Voice-over contributions of mine</p>
              </div>   		
            </div> {/* /section-intro*/}  
            <div className="row portfolio-content">
              <div className="col-twelve">
                {/* portfolio-wrapper */}
                <div id="folio-wrapper" className="block-1-2 block-mob-full stack">
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <iframe title='voiceover' width={560} height={315} src="https://www.youtube.com/embed/videoseries?list=PLu5hsl5dU0VH-Zod9Bp-xYNWWNtwg2309" frameBorder={0} allow="autoplay; encrypted-media" allowFullScreen />
                    </div>	               
                  </div> {/* /folio-item */}
                  <div className="bgrid folio-item">
                    <div className="item-wrap">
                    <img src="images/portfolio/voiceover/3dinternship_banner.jpeg" alt="ICT Breakfast Forum" />
                      <button onClick={()=>this.togglePopup("<iframe src='https://drive.google.com/file/d/1M7Wj8cgPk-v2gvE4zrGj3nH-9sRbIRkw/preview' height='480' width='640' frameborder='0' allowfullscreen='' title='Embedded post' />","article")} className="overlay">   	                  	           
                        <div className="folio-item-table">
                          <div className="folio-item-cell">
                            <h3 className="folio-title">3D Internship<br></br>Program</h3>	     					    
                            <span className="folio-types">
                              Voiceover
                            </span>		     		
                          </div>	                      	
                        </div>                    
                      </button>
                    </div>
                  </div> {/* /folio-item */}
                </div> {/* /portfolio-wrapper */} 
              </div>  {/* /twelve */}   
            </div> {/* /portfolio-content */}
          </section>
        );
    }
};