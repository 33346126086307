import './App.css';
import Intro from './components/intro'
import Resume from './components/resume'
import About from './components/about'
import Services from './components/services'
import Portfolio from './components/portfolio'
import Stats from './components/stats'
import Contact from './components/contact'
import Footer from './components/footer'
import CookieConsent from 'react-cookie-consent';
import React from 'react';


function App() {
  return (
    <div>
    <CookieConsent
      location="bottom"
      buttonText="I UNDERSTAND"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "16px" }}
      expires={0}
    >
    The Site Is Not yet Mobile Optimized For Media Content.<br></br>Please Use A Computer For The Best Experience.
    </CookieConsent>
    <Intro></Intro>
    <About></About>
    <Resume></Resume>
    <Services></Services>
    <Portfolio></Portfolio>
    <Stats></Stats>
    <Contact></Contact>
    <Footer></Footer>
    </div>

  );
}

export default App;
