import React, { Component } from  'react'

export default class Footer extends Component {
    render(){
        return(
            <div>
            <footer>
              <div className="row">
                <div className="col-six tab-full pull-right social">
                  <ul className="footer-social">        
                  <li><a href="https://www.facebook.com/achalawe"><i className="fa fa-facebook" /></a></li>
                  <li><a href="https://www.linkedin.com/in/achalaweerasooriya/"><i className="fa fa-linkedin" /></a></li>
                  <li><a href="https://www.youtube.com/channel/UCh9HN6oE8tFb2R6cTu2Nhbg"><i className="fa fa-youtube" /></a></li>
                  <li><a href="https://www.instagram.com/ach_nbw/"><i className="fa fa-instagram" /></a></li>
                  </ul> 
                </div>
                <div className="col-six tab-full">
                  <div className="copyright">
                    <span>© Copyright Achala Weerasooriya</span> 
                    <span>Design by styleshout</span>	         	
                  </div>		                  
                </div>
                <div id="go-top">
                  <a className="smoothscroll" title="Back to Top" href="#top"><i className="fa fa-long-arrow-up" /></a>
                </div>
              </div> {/* /row */}     	
            </footer>  
            {/* <div id="preloader"> 
              <div id="loader" />
            </div>  */}
            {/* Java Script
       ================================================== */} 
          </div>
        );
    }
};