import React from 'react';
import {ReactVideo} from 'reactjs-media';
// import ReactAudio from 'reactjs-media';
import {FacebookPlayer} from 'reactjs-media';
import {YoutubePlayer} from 'reactjs-media';
import  '../index.css';

class Popup extends React.Component{

    render(){
        /*Popup for articles*/
        if(this.props.type === "article"){
            return(
                <div id="overlay">
                    <div>
                    <h1 style={{color: 'purple'}}> {this.props.text} </h1>
                        <div dangerouslySetInnerHTML={{ __html: this.props.link ? this.props.link :""}}/>
                        <button onClick={this.props.closePopup}> close me </button>
                    </div>
                </div>
                
            );
        }
        /*Popup for FaceBook Videos*/
        else if(this.props.type === "facebookvid"){
            console.log('came in '+this.props.link);
            return(
                <div id="overlay">
                    <div>
                    <h1 style={{color: 'purple'}}> {this.props.text} <br></br>In case the video does not load, please re-load the page.</h1>
                        <div>
                        <FacebookPlayer
                            src={this.props.link ? this.props.link :""}
                            width={600}
                            height={400}
                        />
                        </div>
                        <button onClick={this.props.closePopup}> close me </button>
                    </div>
                </div>
            );
        }
        /*Popup for YouTube Videos*/
        else if(this.props.type === "youtubevid"){
            console.log('came in '+this.props.link);
            return(
                <div id="overlay">
                    <div>
                    <h1 style={{color: 'purple'}}> {this.props.text} </h1>
                        <div>
                            <YoutubePlayer
                            src={this.props.link ? this.props.link :""} // Reqiured
                            width={600}
                            height={400}
                            />
                        </div>
                        <button onClick={this.props.closePopup}> close me </button>
                    </div>
                </div>
                
            );
        }
        /*Popup for Other Videos*/
        else if(this.props.type === "othervid"){
            console.log('came in '+this.props.link);
            return(
                <div id="overlay">
                    <div>
                    <h1 style={{color: 'purple'}}> {this.props.text} </h1>
                        <div>
                        <ReactVideo
                            src={this.props.link ? this.props.link :""}
                            primaryColor="red"
                        />
                        </div>
                        <button onClick={this.props.closePopup}> close me </button>
                    </div>
                </div>
                
            );
        }
        /*Popup for Posts that cannot be embedded*/
        else if(this.props.type === "noembed"){
            console.log('came in '+this.props.link);
            return(
                <div id="overlay">
                    <div>
                    <h1 style={{color: 'purple'}}>Oh No..! Sorry!</h1>
                        <div>
                        <a href={this.props.link} target="_blank" rel="noreferrer"><h3 style={{color: 'pink'}}>Content Cannot be Embedded.<br /><br />Please Click Here To View The Post</h3></a>
                        </div>
                        <button onClick={this.props.closePopup}> close me </button>
                    </div>
                </div>
            );
        }
        /*Unavailable Content*/
        else {
            return(
                <div id="overlay">
                    <div>
                    <h1 style={{color: 'purple'}}> Sorry!<br></br>Content is currently unavailable. </h1>
                        <button onClick={this.props.closePopup}> close me </button>
                    </div>
                </div>
                
            );
        }
    }
}

export default Popup;