import React, { Component } from  'react'
import ReactTooltip from 'react-tooltip'

export default class About extends Component {
  constructor(props){
    super(props);
    this.state = {
      softwaredev:'C++, C#, C, Java, JavaFx, HTML, CSS, ReactJS, NodeJS, SQL, NoSQL, PHP etc',
      businessanalysis:'Requirement Gathering, Software Documentation, Feasibility Study, Client-dev team bridging, Client account handling etc',
      pitching:'Product Pitching, Software Application Pitching, Software and Other Services Pitching for Investors, Clients, Potential Partners, Competitions etc',
      publicspeaking:'Personal Development, Field of IT, Technology, StartUp Ecosystem, Experience Sharing etc',
      brandbuilding:'Ground-Up Stratergy Planning, Story and Concepts, Follower Growth, Showcasing etc ',
      compere:'Event Host, Emcee, Bilingual (Sinhala, English), Moderator',
      contentwriting:'Brand Boosting, Stories, Scripts, Skits, Advertisements, Marketing Material, Branding Material etc',
      contentideation:'Brand new Concepts, Stratergies of Approach, Unique Ideas, Tailormade Concept Building etc',
      voiceover:'Advertisements, Marketing Materials, Product Launch, Intro Video/Audio, Narration etc'
    };
  }
    render(){
        return(
<section id="about"> <ReactTooltip/>
        <div className="row section-intro">
          <div className="col-twelve">
            <h5>About</h5>
            <h1>Let me introduce myself.</h1>
            <div className="intro-info">
              <img src="images/profile-pic.JPEG" alt="Profilepic" />
              <p className="lead">5+ years in the technological sector with local and international experience growing up with a product and services startup with active involvement in development, account handling, business generation, investor pitching, being the first point of contact for the company, team management and lead as well as overseeing operational activities. 
                                  <br></br>Actively involved in Augmented Reality, Virtual Reality, Product Visualization, 3D and graphics based applications, Metaverse and NFTs, etc

                                  <br></br>Also involved in Recruitment, Head-Hunting and HR services sector supporting to grab new business opportunities and fields of services expansion.

                                  <br></br>Freelance Compère / Announcer / Event Host (Emcee), Voice-over artist and a Public Speaker.

                                  <br></br>Leadership, Management Skills and Adaptability along with the courage to take up any challenge played a vital role in building my self up to the point where I am today.</p>
            </div>   			
          </div>   		
        </div> {/* /section-intro */}
        <div className="row about-content">
          <div className="col-six tab-full">
            <h3>Profile</h3>
            <p>More than two decades of life experience gathered in to a single person. Experience in the IT sector with local and international involvements. Growth Consultant for new businesses. Freelance Compère / Announcer / Event Host (Emcee) and a Public Speaker</p>
            <ul className="info-list">
              <li>
                <strong>Fullname:</strong>
                <span>Achala N B Weerasooriya</span>
              </li>
              <li>
                <strong>Birth Date:</strong>
                <span>May 1994</span>
              </li>
              <li>
                <strong>Industry:</strong>
                <span>Tech , Media , HR , Tourism , Exports</span>
              </li>
              <li>
                <strong>Website:</strong>
                <span>achalaweerasooriya.com</span>
              </li>
              <li>
                <strong>Email:</strong>
                <span>me@achalaweerasooriya.com</span>
              </li>
            </ul> {/* /info-list */}
          </div>
          <div className="col-six tab-full">
            <h3>Skills</h3>
            <p>Hover your mouse pointer on each skill to see more details.</p>
            <ul className="skill-bars">
              <li data-tip={this.state.softwaredev}>
                <div className="progress percent85"><span>85%</span></div>
                <strong>Software Development</strong>
              </li>
              <li data-tip={this.state.businessanalysis}>
                <div className="progress percent90"><span>90%</span></div>
                <strong>Business Analysis</strong>
              </li>
              <li data-tip={this.state.pitching}>
                <div className="progress percent90"><span>90%</span></div>
                <strong>Pitching</strong>
              </li>
              <li data-tip={this.state.publicspeaking}>
                <div className="progress percent95"><span>95%</span></div>
                <strong>Public Speaking</strong>
              </li>
              <li data-tip={this.state.brandbuilding}>
                <div className="progress percent80"><span>80%</span></div>
                <strong>Brand Building</strong>
              </li>
              <li data-tip={this.state.compere}>
                <div className="progress percent90"><span>90%</span></div>
                <strong>Compèring</strong>
              </li>
              <li data-tip={this.state.contentwriting}>
                <div className="progress percent80"><span>80%</span></div>
                <strong>Content Writing</strong>
              </li>
              <li data-tip={this.state.contentideation}>
                <div className="progress percent90"><span>90%</span></div>
                <strong>Content Ideation</strong>
              </li>
              <li data-tip={this.state.voiceover}>
                <div className="progress percent90"><span>80%</span></div>
                <strong>Voice-over Work</strong>
              </li>
            </ul>{/* /skill-bars */}		
          </div>
        </div>
        <div className="row button-section">
          <div className="col-twelve">
            <a href="#contact" title="Say Hello" className="button stroke smoothscroll">Say Hello!</a>
            <a href="#intro" title="Connect Online" className="button button-primary">Connect Online</a>
          </div>   		
        </div>
      </section> 
        );
    }
};