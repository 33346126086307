import React, { Component } from  'react'

export default class Services extends Component {
    render(){
        return(
<section id="services">
        <div className="overlay" />
        <div className="row section-intro">
          <div className="col-twelve">
            <h5>My Strengths</h5>
            <h1>What Can We Do Together?</h1>
            <p className="lead">Are you a thinker, planning to grow yourself and bring yourself out of your comfort zone needing insights and guidance to boost your journey? Do you think my skills and competencies would give a positive advantage to go to your dream or goal? Do you think you have the courage and the dedication to do what is needed in the right time? If so, feel free to reach-out. I will be more than happy to assist you.</p>
          </div>   		
        </div> {/* /section-intro */}
        <div className="row services-content">
          <div id="owl-slider" className="owl-carousel services-list">
            <div className="service">	
              <span className="icon"><i className="icon-earth" /></span>            
              <div className="service-content">	
                <h3>Webdesign</h3>
                <p className="desc">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.
                </p>
              </div> 	         	 
            </div> {/* /service */}
            <div className="service">	
              <span className="icon"><i className="icon-window" /></span>                          
              <div className="service-content">	
                <h3>Web Development</h3>  
                <p className="desc">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.
                </p>
              </div>	                          
            </div> {/* /service */}
            <div className="service">
              <span className="icon"><i className="icon-paint-brush" /></span>		            
              <div className="service-content">
                <h3>Branding</h3>
                <p className="desc">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.
                </p> 
              </div> 	            	               
            </div> {/* /service */}
            <div className="service">
              <span className="icon"><i className="icon-toggles" /></span>	              
              <div className="service-content">
                <h3>UI/UX Design</h3>
                <p className="desc">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.
                </p> 
              </div>                
            </div> {/* /service */}
            <div className="service">
              <span className="icon"><i className="icon-image" /></span>	            
              <div className="service-content">
                <h3>Graphics Design</h3>
                <p className="desc">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.
                </p> 
              </div>	               
            </div> {/* /service */}
            <div className="service">
              <span className="icon"><i className="icon-chat" /></span>	   	           
              <div className="service-content">
                <h3>Consultancy</h3>
                <p className="desc">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.
                </p> 
              </div>	               
            </div> {/* /service */}
          </div> {/* /services-list */}
        </div> {/* /services-content */}
      </section>
        );
    }
};