import React, { Component } from  'react'

export default class Resume extends Component {
    render(){
        return(
<section id="resume" className="grey-section">
        <div className="row section-intro">
          <div className="col-twelve">
            <h5>Know More</h5>
            <h1>More of my credentials.</h1>
            <p className="lead">Leadership, Management Skills and Adaptability along with the courage to take up any challenge played a vital role in building my self up to the point where I am today.</p>
            <p className="lead">A free person.</p>
            <p className="lead">I know my weaknesses but am never pondering on them for I know my strengths worth more. Positive thinking leads the way in capturing the opportunities and gaining results.</p>
          </div>   		
        </div> {/* /section-intro*/} 
        <div className="row resume-timeline">
          <div className="col-twelve resume-header">
            <h2>IT Related Experience</h2>
          </div> {/* /resume-header */}
          <div className="col-twelve">
            <div className="timeline-wrap">
            <div className="timeline-block">
                <div className="timeline-ico">
                  <i className="fa fa-plane"/>
                </div>
                <div className="timeline-header">
                  <h3>Chief Executive Officer</h3>
                  <p>June 2022 - Present</p>
                </div>
                <div className="timeline-content">
                  <h4>LiveRoom (Pvt) Ltd</h4>
                  <p>Taking the lead in the strategic planning and execution. Guiding and growing the team towards the goals and targets while supporting the teams career growth as well as company's expectations.</p>
                </div>
              </div> {/* /timeline-block */}
            <div className="timeline-block">
                <div className="timeline-ico">
                  <i className="fa fa-plane"/>
                </div>
                <div className="timeline-header">
                  <h3>Chief Operations Officer</h3>
                  <p>May 2021 - June 2022</p>
                </div>
                <div className="timeline-content">
                  <h4>LiveRoom (Pvt) Ltd</h4>
                  <p>Handling operational activities of the organisation. Leading the Business Analysis and Pre-sales team. Lead generation, Product Pitching, Client account handling.</p>
                </div>
              </div> {/* /timeline-block */}
              <div className="timeline-block">
                <div className="timeline-ico">
                  <i className="fa fa-briefcase"/>
                </div>
                <div className="timeline-header">
                  <h3>COO-cum-Software Engineer</h3>
                  <p>April 2019 - April 2021</p>
                </div>
                <div className="timeline-content">
                  <h4>LiveRoom (Pvt) Ltd</h4>
                  <p>Leading the Business Analysis and Pre-sales team. Product Development for embedded systems, Client account handling.</p>
                </div>
              </div> {/* /timeline-block */}
              <div className="timeline-block">
                <div className="timeline-ico">
                  <i className="fa fa-flask" />
                </div>
                <div className="timeline-header">
                  <h3>Associate Software Engineer</h3>
                  <p>December 2016 - April 2019</p>
                </div>
                <div className="timeline-content">
                  <h4>LiveRoom (Pvt) Ltd</h4>
                  <p>Was a part of the outsourced development team for embedded systems. Was a member of the development teams for web projects as well as AR/VR/MR projects as a developer.</p>
                </div>
              </div> {/* /timeline-block */}
              <div className="timeline-block">
                <div className="timeline-ico">
                  <i className="fa fa-magic" />
                </div>
                <div className="timeline-header">
                  <h3>Software Engineer Trainee</h3>
                  <p>January 2016 - November 2016</p>
                </div>
                <div className="timeline-content">
                  <h4>LiveRoom (Pvt) Ltd</h4>
                  <p>Development of the company website. Was a development member for the very first version of the company product which is an AR marketing and sales platform for e-commerce</p>
                </div>
              </div> {/* /timeline-block */}
            </div> {/* /timeline-wrap */}   			
          </div> {/* /col-twelve */}
        </div> {/* /resume-timeline */}
        <div className="row resume-timeline">
          <div className="col-twelve resume-header">
            <h2>Entrepreneurial Experiences</h2>
          </div> {/* /resume-header */}
          <div className="col-twelve">
            <div className="timeline-wrap">
              <div className="timeline-block">
                <div className="timeline-ico">
                  <i className="fa fa-microphone" />
                </div>
                <div className="timeline-header">
                  <h3>Co-Founder / Director</h3>
                  <p>June 2021 - Present</p>
                </div>
                <div className="timeline-content">
                  <h4>Innovera (Pvt) Ltd</h4>
                  <p>Looking into new business opportunities and paths to grow the service sector and fields of businesses. Expanded services from Recruitment and Head-hunting to HR services, Job portal creation, Business Expansion, Travel and tourism, Exports. Also supports and consults companies in building up from scratch either newly in Sri Lanka or trying to expand into Sri Lanka.</p>
                </div>
              </div> {/* /timeline-block */}
            </div> {/* /timeline-wrap */}   			
          </div> {/* /col-twelve */}
        </div> {/* /resume-timeline */}
        <div className="row resume-timeline">
          <div className="col-twelve resume-header">
            <h2>Media Sector Experiences</h2>
          </div> {/* /resume-header */}
          <div className="col-twelve">
            <div className="timeline-wrap">
              <div className="timeline-block">
                <div className="timeline-ico">
                  <i className="fa fa-microphone" />
                </div>
                <div className="timeline-header">
                  <h3>Director / Head of Growth</h3>
                  <p>June 2022 - Present</p>
                </div>
                <div className="timeline-content">
                  <h4>Vaag Media (Pvt) Ltd</h4>
                  <p>Exploring new business opportunities and growth of services while maintaining the target focus of the business and it's value addition to the clients.</p>
                </div>
              </div> {/* /timeline-block */}
            </div> {/* /timeline-wrap */}   			
          </div> {/* /col-twelve */}
        </div> {/* /resume-timeline */}
        <div className="row resume-timeline">
          <div className="col-twelve resume-header">
            <h2>Other Experiences</h2>
          </div> {/* /resume-header */}
          <div className="col-twelve">
            <div className="timeline-wrap">
              <div className="timeline-block">
                <div className="timeline-ico">
                  <i className="fa fa-microphone" />
                </div>
                <div className="timeline-header">
                  <h3>Compère</h3>
                  <p>Since 2013</p>
                </div>
                <div className="timeline-content">
                  <h4>Freelance</h4>
                  <p>Since leaving school, continuing the passion I had towards compèring, Hosting, Announcing I was working in those areas covering events from small gatherings up to co-operate events.</p>
                </div>
              </div> {/* /timeline-block */}
              <div className="timeline-block">
                <div className="timeline-ico">
                  <i className="fa fa-pencil" />
                </div>
                <div className="timeline-header">
                  <h3>Written Content Creation</h3>
                  <p>Since 2010</p>
                </div>
                <div className="timeline-content">
                  <h4>Self Employed</h4>
                  <p>As a result of the load of reading I did in my school days, the passion in creating written content was sharpened and hence was actively writing short-stories, skits, scripts etc</p>
                </div>
              </div> {/* /timeline-block */}
              <div className="timeline-block">
                <div className="timeline-ico">
                  <i className="fa fa-youtube-play" />
                </div>
                <div className="timeline-header">
                  <h3>Video Content Creation</h3>
                  <p>Since 2018</p>
                </div>
                <div className="timeline-content">
                  <h4>Self Employed</h4>
                  <p>With the intention of using my skill of speaking, expressing the ideas to a mass audience, stepped in to video creation in the segment of informative content.</p>
                </div>
              </div> {/* /timeline-block */}
              <div className="timeline-block">
                <div className="timeline-ico">
                  <i className="fa fa-arrow-circle-o-up" />
                </div>
                <div className="timeline-header">
                  <h3>Ground Up Brand Building</h3>
                  <p>Since 2020</p>
                </div>
                <div className="timeline-content">
                  <h4>Consultancy</h4>
                  <p>Putting the ideation and creative thinking skills in to play, I have taken up the challenge of building personalities and brands from ground up.</p>
                </div>
              </div> {/* /timeline-block */}
              <div className="timeline-block">
                <div className="timeline-ico">
                  <i className="fa fa-road" />
                </div>
                <div className="timeline-header">
                  <h3>Mentoring</h3>
                  <p>Since 2020</p>
                </div>
                <div className="timeline-content">
                  <h4>Volunteer</h4>
                  <p>Have taken up mentoring the upcoming young and enthusiastic minds to think differrent and act different making their dreams and goals more realistic and showing them the endless possibilities that lies ahead.</p>
                </div>
              </div> {/* /timeline-block */}
            </div> {/* /timeline-wrap */}   			
          </div> {/* /col-twelve */}
        </div> {/* /resume-timeline */}
        <div className="row resume-timeline">
          <div className="col-twelve resume-header">
            <h2>Education</h2>
          </div> {/* /resume-header */}
          <div className="col-twelve">
            <div className="timeline-wrap">
              <div className="timeline-block">
                <div className="timeline-ico">
                  <i className="fa fa-briefcase" />
                </div>
                <div className="timeline-header">
                  <h3>Masters Degree</h3>
                  <p>Reading</p>
                </div>
                <div className="timeline-content">
                  <h4>University of Westminister</h4>
                  <p>MSc in Advance Software Engineering affiliated IIT Sri Lanka</p>
                </div>
              </div> {/* /timeline-block */}
              <div className="timeline-block">
                <div className="timeline-ico">
                  <i className="fa fa-briefcase" />
                </div>
                <div className="timeline-header">
                  <h3>Bachelor Degree</h3>
                  <p>January 2014 - December 2017</p>
                </div>
                <div className="timeline-content">
                  <h4>Sri Lanka Institute of Information Technology</h4>
                  <p>BSc(hons) in IT Specialised in Software Engineering.</p>
                </div>
              </div> {/* /timeline-block */}
              <div className="timeline-block">
                <div className="timeline-ico">
                  <i className="fa fa-briefcase" />
                </div>
                <div className="timeline-header">
                  <h3>Primary and Secondary Education</h3>
                  <p>2000 - 2013</p>
                </div>
                <div className="timeline-content">
                  <h4>Dharmaraja College - Kandy</h4>
                  <p>School days were awesome and that is where all these great things started.</p>
                </div>
              </div> {/* /timeline-block */}
            </div> {/* /timeline-wrap */}   			
          </div> {/* /col-twelve */}
        </div> {/* /resume-timeline */}
      </section>
        );
    }
};